//main theme colors
$lp-primary: #1976d2;
$lp-secondary: #ff9100;
$lp-tertiary: #ffd180;

//alerts
$lp-warning: #d50000;
$lp-success: #aed581;
$lp-success--light: lighten(#aed581, 10);

//visual elements: charts, dashboards
$lp-orange--dark: #fcbe86;
$lp-orange--medium: #f9d1a8;
$lp-orange--light: #ffe5ca;
$lp-yellow--dark: #ffe08a;
$lp-yellow--light: #fff7c0;
$lp-blue--dark: #85d2ed;
$lp-blue--light: #d6e8f7;
$lp-pink--dark: #e0007f;
$lp-pink--light: #fbe0f0;

//greyscale
$lp-greyscale--00: #0e0e0e;
$lp-greyscale--01: #333333;
$lp-greyscale--02: #888888;
$lp-greyscale--03: #aaaaaa;
$lp-greyscale--04: #cecece;
$lp-greyscale--05: #d4d4d4;
$lp-greyscale--06: #e0e0e0;
$lp-greyscale--07: #e6e6e6;
$lp-greyscale--08: #eeeeee;
$lp-greyscale--09: #f2f2f2;
$lp-greyscale--10: #ffffff;

//color loop for sticker progress bar
$stickers: ("Ready_For_Launch", $lp-blue--light, $lp-blue--dark),
  //("Total", $lp-primary, $lp-primary),
  ("Total", #86d2ed, #1876d2),
  ("Item_Data_Ready", #AFE8D7, #00A876),
  ("Item_Order_Ready", #AFE8D7, #00A876),
  ("Ready_for_Launch", #AFE8D7,#00A876),
  ("Item_Data_Not_Ready", #FFD4EA, #FE0081);

@each $stickertype, $color_02, $color_01 in $stickers {
  .#{$stickertype}.lp-sticker {
    .C-ProgressBar__progress {
      fill: $color_01;
      background:$color_01;
    }
    .C-ProgressBar {
      fill: $color_02;
      background:$color_02;
      &.--success {
        fill: $color_02;;
        background:$color_02;
      }
      .C-ProgressBar__progress {
        fill: $color_01;
        background:$color_01;
      }
    }
    .ct-series.ct-series-b .ct-slice-donut {
      fill: $color_02;
      background-color:$color_02;
    }
  }
}

.lp-sticker-card {
  width: 120px;
  height: 80px;
  display: flex;
  flex-direction: column;
  //width: 200px; /* Adjust width as needed */
  //height: 100px; /* Adjust height as needed */
  background-color: #fff;
  border: 1px solid #00A876;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &.lp-sticker-active {
    background-color: #e8f6ef !important;
  }
}
.lp-sticker-card-new {
  width: 120px;
  height: 80px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #FE0081;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &.lp-sticker-active {
    background-color: #FFF2F9 !important;
  }
}
.lp-sticker-card-new-dotted {
  width: 120px;
  height: 80px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px dashed #FE0081;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &.lp-sticker-active {
    background-color: #FFF2F9 !important;
  }
}
.lp-sticker-card-total {
  width: 120px;
  height: 80px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px dashed #1876d2;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &.lp-sticker-active {
    background-color: #e4f1fc !important;
  }
}
.lp-sticker-title {
  flex: 1; /* Take up remaining space after bottom section */
  display: flex;
  justify-content: left;
  align-items: flex-start;
  font-size: 12px;
  font-weight: 400;
  padding-left: 4%;
}
.lp-sticker-value {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
}
.lp-sticker-bottom {
  text-align: center;
  padding: 2% 2% 0;
  font-weight: 700;
}
.progress-fill {
  transition: width 0.3s ease-in-out; /* Add smooth transition */
  background-color: #AFE8D7;
}
.progress-fill-new {
  transition: width 0.3s ease-in-out; /* Add smooth transition */
  background-color: #FFD4EA
}
.progress-pre {
  color: #00A876; // outline color
}
.progress-pre-new {
  color: #FE0081; // outline color
}
.lp-sticker {
  //padding: 0;
  text-align: center;
  font-size: 14px;
  outline: 0;
  display: flex; /* Arrange elements horizontally */
  position: relative;
  &__container {
    outline: 0;
  }
  &__container:focus &,
  &__container:hover & {
    background: $lp-greyscale--09;
    cursor: pointer;
  }
  .lp-sticker--active & {
    box-shadow: inset 0 3px 5px 2px $lp-greyscale--05;
    background: $lp-greyscale--09;
  }
  .blocker-count {
    font-size: 12px;
    position: absolute;
    top: 66px;
    left: 0;
    display: inline-block;
    z-index: 20;
    width: 100%;
    text-anchor: middle;
  }
  .C-ProgressBar {
    fill: #FFD4EA;
    background-color:#FFD4EA;
    &.--success {
      fill: #FE0081;
      background:#FE0081;
    }
    .C-ProgressBar__progress {
      fill: #FE0081;
      background:#FE0081;
    }
  }
  .ct-series.ct-series-b .ct-slice-donut {
    fill: #FE0081;
    background-color:#FE0081;
  }
}
//styles for the item results summary card
.sticker-list {
  min-height: 100px;
  ul {
    display: flex;
    list-style: none;
  }
  li.vertical-hr{
    .C-ListItem__item{
      margin-right: 12px;
    }
  }
  .place-holder {
    width: 120px;
    height: 80px;
    border-radius: var(--canvas-border-radius--dense);
  }
  .vertical-line {
    width: 1px; /* Set desired line width */
    height: 80px; /* Set desired line height (or use percentages) */
    background-color: #FE0081; /* Set line color */
    margin-left: 16px;
    display: flex; /* Arrange elements horizontally */
    position: absolute;
    top: 10px;
    left: 115px;
  }

}
