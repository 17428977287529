.login-card {
  height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.progress-wrapper {
  position: relative;
  display: inline-block;
  img {
    position: absolute;
    top: 20%;
    left: 25%;
    width: 50%;
    height: 50%;
    transform-origin: center center;
  }
  .lp-spinner {
    width: 2em;
    height: 2em;
    border-radius: 50px;
  }
}
