.bannerTitle {
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  margin: 2px 0;
  line-height: 18px;
}

.bannerdesc {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  margin: 10px 0;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
}

.alertIconContainer,
.dismissContainer {
  text-align: center;
}

.redAlert {
  background: #fde7e7;
  color: #9e0a0a;
}

.yellowAlert {
  background: #ffe3d4;
  color: #931e00;
}

.greenAlert {
  background: #e7fdf6;
  color: #056131;
}

.infoAlert {
  background: #1976d2;
  color: var(--canvas-background-color--app);
}

.helplinkStyle {
  color: #366cd9;
  text-align: right;
  font-size: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  bottom: 15px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.bannerContainer {
  display: flex;
  justify-content: space-evenly;
  margin: 1em 0;
}

.bannerSubContainerWidth {
  width: 90%;
}

.activeBannerCardContainer,
.cardContainer {
  padding: 1em;
  width: 90%;
  margin: auto;
  margin-top: 2em;
}

.bannerControl {
  margin-top: 1em;
  height: 40px;
}

.removeBannerIcon {
  height: 1.2em;
  width: 1.2em;
}

.title {
  color: var(--canvas-helper-color--grey03);
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto";
  font-style: normal;
}

.subTitle {
  color: var(--canvas-helper-color--grey03);
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto";
  font-style: normal;
  line-height: 28px;
}

.textField {
  width: 50%;
}

.textFieldStyle {
  width: 90%;
}

.formControl {
  margin-top: 24px;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.dateLabelStyle {
  margin-bottom: 10px;
}

.alertTypeContainer {
  display: flex;
  align-items: center;
  line-height: 0;
  margin: 0;
}

.bannerActionButtonStyle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #3e72db;
  margin-right: 15px;
  height: 36px;
  width: 96px;
}

.bannerActionSaveButtonStyle {
  color: var(--canvas-background-color--app);
}
.banner-header-position {
  grid-column: 1 / span 4;
  position: relative;
  top: 5em;
  margin-bottom: 10px !important;
  left: 6em;
  width: 98% !important;
}
