.C-Layout__body {
  margin-top: 4em;
}

.show-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.show-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--canvas-helper-color--grey03);
  box-shadow: 0 0 1px var(--canvas-helper-color--grey01);
}
.display-flex {
  display: flex;
}
.display-grid {
  display: grid;
}
.link-color {
  color: var(--canvas-border-color--interactive);
}
.app-bg-color {
  background-color: var(--canvas-background-color--app);
}
.layout-body-section {
  grid-column: 2 / span 4 !important;
  //margin: 4em 0.5em;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.C-SideNav__NavigationItem.home-menu.active,
.C-SideNav__NavigationItem.other-menu.active {
  background: var(--canvas-background-color--default-active);
  color: var(--canvas-font-color--default);
}

.font-color-default {
  color: var(--canvas-font-color--default);
}

.font-color-secondary {
  color: var(--canvas-font-color--secondary);
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.bg-default-contrast-weak {
  background: var(--canvas-background-color--default-contrast-weak) !important;
}
.bg-default-interactive {
  background: var(--canvas-background-color--interactive) !important;
}

.display-none {
  display: none;
}
.visibility-hidden {
  visibility: hidden;
}

.cursor-move {
  cursor: move;
}
.cursor-pointer {
  cursor: pointer;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.width-fit-content {
  min-width: fit-content;
}

.center-align-items {
  align-items: center;
}

.width-70-percent {
  width: 70%;
}

$color-676B74: #676b74;
.font-color-interactive {
  color: var(--canvas-font-color--interactive);
}
.bg-color-interactive {
  background-color: #e8eefc !important;
  border: 1px solid var(--canvas-background-color--app) !important;
}
.C-Layout .layout-body-full {
  grid-column: 1 / span 6 !important;
}
