.dashboard-welcome-block {
  height: 105px;
}
.mydashboard-action-header {
  display: flex;
  justify-content: space-between;
}
.saved-search-button {
  font-weight: 500 !important;
}

.dashboard-saved-search-action {
  height: 565px;
}
.quick-navigation-icon {
  border: 1px solid;
  border-radius: 50%;
  padding: 4px;
  min-width: 30px !important;
  height: 30px !important;
}
.saved-search-lists-main-block {
  display: flex;
}
.saved-search-display-block {
  width: 120px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.saved-search-nro-block {
  background: #FFFCF1;
}
.saved-search-nro-block-percentage {
  background: #FED965;
  padding: 5px;
}

.saved-search-nrl-block {
  background: #FFF4F4;
}
.saved-search-nrl-block-percentage {
  background: #FFA8A8;
  padding: 5px;
}

.saved-search-rl-block {
  background: #F3FFFD;
}
.saved-search-rl-block-percentage {
  background: #7DF7E1;
  padding: 5px;
}
.saved-search-total-block {
  background: #D6EBFF;
}
.saved-search-action-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-container-dashboard .C-Modal__container{
  padding: 12px;
  overflow: auto;
}
.chip-color-interactive{
  min-width: 70px;
  color: var(--canvas-font-color--interactive) !important;
  border:1px solid #fff;
}
.dashboard-item-hover{
  cursor: pointer;
}
.dashboard-item-content{
  overflow: auto;
}

.scrollable-content{
  max-height: 400px;
  overflow-y: scroll;
  flex: 1;
}
